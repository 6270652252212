export const tyreTruckTableField = [
  {
    text: 'langkey.size',
    value: 'size',
    minWidth: {
      desktop: '90px',
      desktopLg: '90px',
    },
    maxWidth: {
      desktop: '1fr',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.tube-type-tubeless',
    value: 'tubeType',
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.load-index',
    value: 'loadIndex',
    sortable: true,
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.speed-index',
    value: 'speedIndex',
    sortable: true,
    minWidth: {
      desktop: '20px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '20px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.manufacturer',
    value: 'manufacturer',
    sortable: true,
    minWidth: {
      desktop: '90px',
      desktopLg: '90px',
    },
    maxWidth: {
      desktop: '90px',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.profile',
    value: 'profile',
    sortable: true,
    minWidth: {
      desktop: '110px',
      desktopLg: '140px',
    },
    maxWidth: {
      desktop: '110px',
      desktopLg: '1fr',
    },
  },
  {
    text: '',
    value: 'rollingResistance',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: '',
    value: 'wetGrip',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: '',
    value: 'noiseClass',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'RE',
    align: 'center',
    value: 'prefix',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'S',
    align: 'center',
    value: 'season',
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.list-price',
    align: 'center',
    value: 'prices',
    minWidth: {
      desktop: '50px',
      desktopLg: '54px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '65px',
    },
  },
  {
    text: '%',
    align: 'center',
    value: 'discount',
    minWidth: {
      desktop: '50px',
      desktopLg: '54px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '65px',
    },
  },
  {
    text: 'langkey.pp',
    align: 'center',
    value: 'purchasePrice',
    sortable: true,
    minWidth: {
      desktop: '50px',
      desktopLg: '54px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '65px',
    },
  },
  {
    text: 'langkey.shipment-type',
    align: 'center',
    value: 'expressAvailable',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.offer',
    align: 'center',
    value: 'data-table-expand',
    minWidth: {
      desktop: '38px',
      desktopLg: '38px',
    },
    maxWidth: {
      desktop: '38px',
      desktopLg: '45px',
    },
  },
];

export const tyreTruckTableFieldDE = [
  {
    text: 'langkey.size',
    value: 'size',
    minWidth: {
      desktop: '90px',
      desktopLg: '90px',
    },
    maxWidth: {
      desktop: '1fr',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.tube-type-tubeless',
    value: 'tubeType',
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.load-index',
    value: 'loadIndex',
    sortable: true,
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.speed-index',
    value: 'speedIndex',
    sortable: true,
    minWidth: {
      desktop: '20px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '20px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.manufacturer',
    value: 'manufacturer',
    sortable: true,
    minWidth: {
      desktop: '90px',
      desktopLg: '90px',
    },
    maxWidth: {
      desktop: '90px',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.profile',
    value: 'profile',
    sortable: true,
    minWidth: {
      desktop: '110px',
      desktopLg: '140px',
    },
    maxWidth: {
      desktop: '110px',
      desktopLg: '1fr',
    },
  },
  {
    text: '',
    value: 'rollingResistance',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: '',
    value: 'wetGrip',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: '',
    value: 'noiseClass',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'RE',
    align: 'center',
    value: 'prefix',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'S',
    align: 'center',
    value: 'season',
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.list-price',
    align: 'center',
    value: 'prices',
    minWidth: {
      desktop: '50px',
      desktopLg: '54px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '65px',
    },
  },
  {
    text: '%',
    align: 'center',
    value: 'discount',
    minWidth: {
      desktop: '50px',
      desktopLg: '54px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '65px',
    },
  },
  {
    text: 'langkey.pp',
    align: 'center',
    value: 'purchasePrice',
    sortable: true,
    minWidth: {
      desktop: '50px',
      desktopLg: '54px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '65px',
    },
  },
  {
    text: 'langkey.de-min',
    align: 'center',
    value: 'eligibilityValue',
    sortable: true,
    minWidth: {
      desktop: '70px',
      desktopLg: '70px',
    },
    maxWidth: {
      desktop: '70px',
      desktopLg: '100px',
    },
  },
  {
    text: 'langkey.shipment-type',
    align: 'center',
    value: 'expressAvailable',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.offer',
    align: 'center',
    value: 'data-table-expand',
    minWidth: {
      desktop: '38px',
      desktopLg: '38px',
    },
    maxWidth: {
      desktop: '38px',
      desktopLg: '45px',
    },
  },
];

export const tyreTruckRetailPriceTableField = [
  {
    text: 'langkey.size',
    value: 'size',
    minWidth: {
      desktop: '95px',
      desktopLg: '95px',
    },
    maxWidth: {
      desktop: '1fr',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.tube-type-tubeless',
    value: 'tubeType',
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.load-index',
    value: 'loadIndex',
    sortable: true,
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.speed-index',
    value: 'speedIndex',
    sortable: true,
    minWidth: {
      desktop: '32px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '32px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.manufacturer',
    value: 'manufacturer',
    sortable: true,
    minWidth: {
      desktop: '90px',
      desktopLg: '90px',
    },
    maxWidth: {
      desktop: '90px',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.profile',
    value: 'profile',
    sortable: true,
    minWidth: {
      desktop: '150px',
      desktopLg: '150px',
    },
    maxWidth: {
      desktop: '150px',
      desktopLg: '1fr',
    },
  },
  {
    text: '',
    value: 'rollingResistance',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: '',
    value: 'wetGrip',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: '',
    value: 'noiseClass',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'RE',
    align: 'center',
    value: 'prefix',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'S',
    align: 'center',
    value: 'season',
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.netto',
    align: 'center',
    value: 'retailPrice',
    sortable: true,
    minWidth: {
      desktop: '60px',
      desktopLg: '64px',
    },
    maxWidth: {
      desktop: '60px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.brutto',
    align: 'center',
    value: 'retailTaxPrice',
    sortable: true,
    minWidth: {
      desktop: '60px',
      desktopLg: '64px',
    },
    maxWidth: {
      desktop: '60px',
      desktopLg: '75px',
    },
  },
  {
    text: '',
    align: 'center',
    value: 'offer',
    minWidth: {
      desktop: '60px',
      desktopLg: '64px',
    },
    maxWidth: {
      desktop: '60px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.shipment-type',
    align: 'center',
    value: 'expressAvailable',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
  {
    text: '',
    align: 'center',
    value: 'data-table-expand',
    minWidth: {
      desktop: '38px',
      desktopLg: '38px',
    },
    maxWidth: {
      desktop: '38px',
      desktopLg: '45px',
    },
  },
];

export const tyreTruckRetailPriceTableFieldDE = [
  {
    text: 'langkey.size',
    value: 'size',
    minWidth: {
      desktop: '85px',
      desktopLg: '85px',
    },
    maxWidth: {
      desktop: '1fr',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.tube-type-tubeless',
    value: 'tubeType',
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.load-index',
    value: 'loadIndex',
    sortable: true,
    minWidth: {
      desktop: '45px',
      desktopLg: '45px',
    },
    maxWidth: {
      desktop: '45px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.speed-index',
    value: 'speedIndex',
    sortable: true,
    minWidth: {
      desktop: '32px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '32px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.manufacturer',
    value: 'manufacturer',
    sortable: true,
    minWidth: {
      desktop: '80px',
      desktopLg: '80px',
    },
    maxWidth: {
      desktop: '80px',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.profile',
    value: 'profile',
    sortable: true,
    minWidth: {
      desktop: '135px',
      desktopLg: '135px',
    },
    maxWidth: {
      desktop: '135px',
      desktopLg: '1fr',
    },
  },
  {
    text: '',
    value: 'rollingResistance',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '23px',
      desktopLg: '27px',
    },
    maxWidth: {
      desktop: '23px',
      desktopLg: '65px',
    },
  },
  {
    text: '',
    value: 'wetGrip',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '23px',
      desktopLg: '27px',
    },
    maxWidth: {
      desktop: '23px',
      desktopLg: '65px',
    },
  },
  {
    text: '',
    value: 'noiseClass',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '23px',
      desktopLg: '27px',
    },
    maxWidth: {
      desktop: '23px',
      desktopLg: '65px',
    },
  },
  {
    text: 'RE',
    align: 'center',
    value: 'prefix',
    sortable: true,
    minWidth: {
      desktop: '23px',
      desktopLg: '27px',
    },
    maxWidth: {
      desktop: '23px',
      desktopLg: '65px',
    },
  },
  {
    text: 'S',
    align: 'center',
    value: 'season',
    minWidth: {
      desktop: '23px',
      desktopLg: '27px',
    },
    maxWidth: {
      desktop: '23px',
      desktopLg: '65px',
    },
  },
  {
    text: 'langkey.netto',
    align: 'center',
    value: 'retailPrice',
    sortable: true,
    minWidth: {
      desktop: '60px',
      desktopLg: '64px',
    },
    maxWidth: {
      desktop: '60px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.brutto',
    align: 'center',
    value: 'retailTaxPrice',
    sortable: true,
    minWidth: {
      desktop: '60px',
      desktopLg: '64px',
    },
    maxWidth: {
      desktop: '60px',
      desktopLg: '75px',
    },
  },
  {
    text: '',
    align: 'center',
    value: 'offer',
    minWidth: {
      desktop: '40px',
      desktopLg: '44px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '55px',
    },
  },
  {
    text: 'langkey.de-min',
    align: 'center',
    value: 'eligibilityValue',
    sortable: true,
    minWidth: {
      desktop: '70px',
      desktopLg: '70px',
    },
    maxWidth: {
      desktop: '70px',
      desktopLg: '100px',
    },
  },
  {
    text: 'langkey.shipment-type',
    align: 'center',
    value: 'expressAvailable',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
  {
    text: '',
    align: 'center',
    value: 'data-table-expand',
    minWidth: {
      desktop: '38px',
      desktopLg: '38px',
    },
    maxWidth: {
      desktop: '38px',
      desktopLg: '45px',
    },
  },
];

export const tyreTruckTableFieldExpand = [
  { text: 'langkey.supplier', value: 'supplier', width: `${(4/20) * 100}%` },
  { text: 'langkey.stock', value: 'stock', width: `${(2/20) * 100}%` },
  { text: 'langkey.list-price', value: 'listPrice', width: `${(2/20) * 100}%` },
  { text: 'langkey.discount', value: 'discount', width: `${(2/20) * 100}%` },
  { text: 'langkey.price', value: 'purchasePrice', width: `${(2/20) * 100}%` },
  { text: 'langkey.quantity', align: 'center', value: 'qty', width: `${(4/20) * 100}%` },
  { text: '', align: 'center', value: 'expressShipmentAvailable', width: `${(1/20) * 100}%` },
  { text: '', align: 'right', value: 'order', width: `${(1/20) * 100}%` },
];

export const tyreTruckTableFieldExpandDE = [
  { text: 'langkey.supplier', value: 'supplier', width: `${(4/20) * 100}%` },
  { text: 'langkey.stock', value: 'stock', width: `${(2/20) * 100}%` },
  { text: 'langkey.list-price', value: 'listPrice', width: `${(2/20) * 100}%` },
  { text: 'langkey.discount', value: 'discount', width: `${(2/20) * 100}%` },
  { text: 'langkey.price', value: 'purchasePrice', width: `${(2/20) * 100}%` },
  { text: 'langkey.de-min', value: 'eligibilityValue', width: `${(2/20) * 100}%` },
  { text: 'langkey.quantity', align: 'center', value: 'qty', width: `${(4/20) * 100}%` },
  { text: '', align: 'center', value: 'expressShipmentAvailable', width: `${(1/20) * 100}%` },
  { text: '', align: 'right', value: 'order', width: `${(1/20) * 100}%` },
];

export const tyreTruckRetailPriceTableFieldExpand = [
  { text: 'langkey.supplier', value: 'supplier', width: `${(4/20) * 100}%` },
  { text: 'langkey.stock', value: 'stock', width: `${(2/20) * 100}%` },
  { text: 'langkey.netto', value: 'retailPrice', width: `${(3/20) * 100}%` },
  { text: 'langkey.brutto', value: 'retailTaxPrice', width: `${(3/20) * 100}%` },
  { text: 'langkey.quantity', align: 'center', value: 'qty', width: `${(4/20) * 100}%` },
  { text: '', align: 'center', value: 'expressShipmentAvailable', width: `${(1/20) * 100}%` },
  { text: '', align: 'right', value: 'order', width: `${(1/20) * 100}%` },
];

export const tyreTruckRetailPriceTableFieldExpandDE = [
  { text: 'langkey.supplier', value: 'supplier', width: `${(4/20) * 100}%` },
  { text: 'langkey.stock', value: 'stock', width: `${(2/20) * 100}%` },
  { text: 'langkey.netto', value: 'retailPrice', width: `${(3/20) * 100}%` },
  { text: 'langkey.brutto', value: 'retailTaxPrice', width: `${(3/20) * 100}%` },
  { text: 'langkey.de-min', value: 'eligibilityValue', width: `${(2/20) * 100}%` },
  { text: 'langkey.quantity', align: 'center', value: 'qty', width: `${(4/20) * 100}%` },
  { text: '', align: 'center', value: 'expressShipmentAvailable', width: `${(1/20) * 100}%` },
  { text: '', align: 'right', value: 'order', width: `${(1/20) * 100}%` },
];

export const selectFilterFields = [
  {
    key: 'width',
    filterKey: 'widths',
    placeholder: '',
    singleSelectLabel: 'langkey.width',
    pluralSelectLabel: 'langkey.widths',
    single: true,
    required: true,
  },
  {
    key: 'aspectRatio',
    filterKey: 'aspectRatios',
    placeholder: '',
    singleSelectLabel: 'langkey.height',
    pluralSelectLabel: 'langkey.heights',
    single: true,
    required: true,
    requiredValueBefore: ['widths'],
  },
  {
    key: 'diameter',
    filterKey: 'diameters',
    placeholder: '',
    singleSelectLabel: 'langkey.diameter',
    pluralSelectLabel: 'langkey.diameters',
    single: true,
    required: true,
    requiredValueBefore: ['widths', 'aspectRatios'],
  },
  {
    key: 'manufacturer',
    filterKey: 'manufacturers',
    placeholder: '',
    singleSelectLabel: 'langkey.manufacturer',
    pluralSelectLabel: 'langkey.manufacturers',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    searchOnSelect: true,
  },
  {
    key: 'profile',
    filterKey: 'profile',
    placeholder: '',
    singleSelectLabel: 'langkey.profile',
    pluralSelectLabel: 'langkey.profiles',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    searchOnSelect: true,
  },
  {
    key: 'quantity',
    filterKey: 'minStock',
    placeholder: '',
    singleSelectLabel: 'langkey.quantity',
    pluralSelectLabel: 'langkey.quantities',
    single: true,
    isUseLabelInSelectedValue: true,
    customOptions: [
      {
        label: 'langkey.stockmorethan2',
        value: 2,
      },
      {
        label: 'langkey.stockmorethan4',
        value: 4,
      },
      {
        label: 'langkey.stockmorethan6',
        value: 6,
      },
      {
        label: 'langkey.stockmorethan8',
        value: 8,
      },
      {
        label: 'langkey.stockmorethan10',
        value: 10,
      },
    ],
  },
  {
    key: 'eligibilityValues',
    filterKey: 'eligibilityValues',
    placeholder: '',
    singleSelectLabel: 'langkey.deminimis',
    pluralSelectLabel: 'langkey.deminimis',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    single: true,
    searchOnSelect: true,
    isUseLabelInSelectedValue: true,
  },
  {
    key: 'usage',
    filterKey: 'usage',
    placeholder: '',
    singleSelectLabel: 'langkey.usage',
    pluralSelectLabel: 'langkey.usages',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    single: true,
    searchOnSelect: true,
    isUseLabelInSelectedValue: true,
  },
];

export const selectFilterFieldsDisabled = [
  {
    key: 'width',
    filterKey: 'widths',
    placeholder: '',
    singleSelectLabel: 'langkey.width',
    pluralSelectLabel: 'langkey.widths',
    single: true,
    required: true,
    disabled: true,
  },
  {
    key: 'aspectRatio',
    filterKey: 'aspectRatios',
    placeholder: '',
    singleSelectLabel: 'langkey.height',
    pluralSelectLabel: 'langkey.heights',
    single: true,
    required: true,
    requiredValueBefore: ['widths'],
    disabled: true,
  },
  {
    key: 'diameter',
    filterKey: 'diameters',
    placeholder: '',
    singleSelectLabel: 'langkey.diameter',
    pluralSelectLabel: 'langkey.diameters',
    single: true,
    required: true,
    requiredValueBefore: ['widths', 'aspectRatios'],
    disabled: true,
  },
  {
    key: 'manufacturer',
    filterKey: 'manufacturers',
    placeholder: '',
    singleSelectLabel: 'langkey.manufacturer',
    pluralSelectLabel: 'langkey.manufacturers',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    searchOnSelect: true,
  },
  {
    key: 'profile',
    filterKey: 'profile',
    placeholder: '',
    singleSelectLabel: 'langkey.profile',
    pluralSelectLabel: 'langkey.profiles',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    searchOnSelect: true,
  },
  {
    key: 'quantity',
    filterKey: 'minStock',
    placeholder: '',
    singleSelectLabel: 'langkey.quantity',
    pluralSelectLabel: 'langkey.quantities',
    single: true,
    isUseLabelInSelectedValue: true,
    customOptions: [
      {
        label: 'langkey.stockmorethan2',
        value: 2,
      },
      {
        label: 'langkey.stockmorethan4',
        value: 4,
      },
      {
        label: 'langkey.stockmorethan6',
        value: 6,
      },
      {
        label: 'langkey.stockmorethan8',
        value: 8,
      },
      {
        label: 'langkey.stockmorethan10',
        value: 10,
      },
    ],
  },
  {
    key: 'eligibilityValues',
    filterKey: 'eligibilityValues',
    placeholder: '',
    singleSelectLabel: 'langkey.deminimis',
    pluralSelectLabel: 'langkey.deminimis',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    single: true,
    searchOnSelect: true,
    isUseLabelInSelectedValue: true,
  },
  {
    key: 'usage',
    filterKey: 'usage',
    placeholder: '',
    singleSelectLabel: 'langkey.usage',
    pluralSelectLabel: 'langkey.usages',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    single: true,
    searchOnSelect: true,
    isUseLabelInSelectedValue: true,
  },
];

export const attributeFilterFields = [
  {
    filterKey: 'seasons',
    filterValue: 'S',
    area: 'summer',
    value: 'summer',
    icon: 'summer',
  },
  {
    filterKey: 'seasons',
    filterValue: 'W',
    area: 'winter',
    value: 'winter',
    icon: 'winter',
  },
  {
    filterKey: 'seasons',
    filterValue: 'A',
    area: 'allSeason',
    value: 'allSeason',
    icon: 'all-season',
  },
  {
    filterKey: 'axleSteering',
    filterValue: 'axleSteering',
    area: 'axleSteering',
    value: 'langkey.steer',
    icon: 'truck-steer',
    isBoolean: true,
  },
  {
    filterKey: 'axleDriving',
    filterValue: 'axleDriving',
    area: 'axleDriving',
    value: 'langkey.drive',
    icon: 'truck-drive',
    isBoolean: true,
  },
  {
    filterKey: 'axleTrailer',
    filterValue: 'axleTrailer',
    area: 'axleTrailer',
    value: 'langkey.trailer',
    icon: 'truck-trailer',
    isBoolean: true,
  },
  {
    filterKey: 'manufacturerClasses',
    filterValue: 'premium',
    area: 'premium',
    value: 'langkey.premium',
  },
  // {
  //   filterKey: 'manufacturerClasses',
  //   filterValue: 'economy',
  //   area: 'economy',
  //   value: 'langkey.economy',
  // },
  {
    filterKey: 'manufacturerClasses',
    filterValue: 'budget',
    area: 'budget',
    value: 'langkey.budget',
  },
  {
    filterKey: 'threePeakMountainSnowFlake',
    filterValue: 'threePeakMountainSnowFlake',
    area: 'threePeakMountainSnowFlake',
    value: 'langkey.3mpsf',
    isBoolean: true,
  },
  {
    filterKey: 'retreaded',
    filterValue: 'retreaded',
    area: 'retreaded',
    value: 'langkey.filter-retread',
    isBoolean: true,
  },
];

export const rangeFilterFields = [
  {
    filterKey: 'rollingResistances',
    icon: 'rolling-resistance',
  },
  {
    filterKey: 'wetGrips',
    icon: 'wet-grip',
  },
  {
    filterKey: 'noiseClasses',
    icon: 'volume',
  },
];
